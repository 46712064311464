export default {
  siteTitle: 'Get bonus',
  blogerName: 'DANILA KING',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/danilakingg',
    },
    {
      name: 'telegram',
      url: 'https://t.me/casino_4at',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@danilaking2',
    },
    {
      name: 'kick',
      url: 'https://kick.com/danilakingg936',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c5685001c',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c69acee9f',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c80038e50',
      gameTitle: 'Royal High-Road (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c64670a82',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c08656923',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cf291d173',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c793db91c',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cf3f1014e',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c629abb58',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c4260d9ad',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cc67e43ef',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>DAN</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'DAN',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  counter: true,
  bonus100: 'Bônus 100%',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
